@font-face {
  font-family: InterFont;
  src: url("../public/fonts/inter/Inter-Regular.ttf");
}

@font-face {
  font-family: Fredoka_OneFont;
  src: url("../public/fonts/Fredoka_One/FredokaOne-Regular.ttf");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, input,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, button,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 13px;
  font-family: 'InterFont', serif;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  background-color: #f5f5f5;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
p, span {
  line-height: 20px;
}
.swal2-styled.swal2-confirm {
  background-color: #36AE7C !important;
}
.swal2-html-container p {
  text-align: left;
}

@media screen and (max-width: 768px) {
  #logo-issy, .is-hidden-mobile {
    display: none;
  }

  .is-hidden-desktop {
    display: block !important;
  }

  #home-content {
    height: auto !important;
  }
}

.is-hidden-desktop {
  display: none;
}

#home-content {
  height: calc(475px - 48px);
}